body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-image: url('bg-img1.jpg');
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
}

@media (max-width: 600px) {
  body {
      background-size: cover; /* Ensures the image covers the entire background */
      min-height: 100vh;
  }
}