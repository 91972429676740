/* SearchResults.css */
body{
    background-color: aliceblue;
}
.search-result-card {
    text-align: left;
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: #ccc;
    color: black;
}

.streaming-services {
    margin-top: 20px;
}

.service-card {
    background-color: aliceblue;
    border: 1px solid #ccc;
    padding: 8px;
    width: 200px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.streaming-services-list {
    display: flex;
    flex-wrap: wrap;
}
